import React from "react"
import Staff from "./stylists"

const StylistSection = ({ staff }) => {
  return (
    <section className="relative py-12">
      <div className="border-top" />
      <div className="flex md:mx-18 lg:mx-48 justify-center md:justify-start flex-wrap">
        {staff.map((staffMember, i) => (
          <Staff staff={staffMember} key={i} />
        ))}

      </div>
    </section>
  )
}

export default StylistSection
