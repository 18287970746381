import React from "react"
import { imageUrlFor } from "../../lib/image-url";
import { buildImageObj } from "../../lib/helpers";

const Staff = ({ staff }) => {
  const img = staff?.image ? imageUrlFor(buildImageObj(staff?.image))
  .width(300)
  .height(300)
  .url() : 'https://via.placeholder.com/300'
  const sanitisedTitle = staff?.socialTitle?.replace(/@/g, '')
  const insta = sanitisedTitle ? `@${sanitisedTitle}` : ''

  return (
    <div className="hover:shadow-md m-5 staff-card relative">
      <img className="object-contain" src={img} alt={`Image for ${staff.title}`} />
      <div className="py-5 bg-white relative flex flex-col items-center z-10">
        <div className="bg-white absolute z-0 w-full h-10 transform -mt-12 -skew-y-2"></div>
        <p className="text-gray-800 uppercase font-medium text-xl">{staff.title}</p>
        <p className="text-gray-800 uppercase font-thin text-sm">{staff.bio}</p>
        <a href={staff.link} className="mb-5 mt-8 border-2 px-5 text-gray-800 border-gray-800 uppercase py-3 hover:bg-gray-800 hover:text-white font-medium">Book now</a>
      </div>
      <div className="staff-card-instagram z-0">
        <a
          href={staff.socialURL}
          target="_blank"
          className="text-white instagram-tag"
        >
          <p className="opacity-100 pt-16 text-center font-bold instagram-tag">{insta}</p>
        </a>
      </div>
    </div>
  )
}

export default Staff
