import React from "react";
import { graphql } from "gatsby";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import LazyLoad from 'react-lazyload'

import GraphQLErrorList from "../components/graphql-error-list";
import Skew from '../components/skew'
import LocationHours from "../components/location-hours"
import StylistSection from "../components/stylists/stylists-section"
import BookNow from "../components/book-now"
import GrizzlyLook from "../components/grizzly-look";
import { Heading, Subheading } from "../components/headings"
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query LocationTemplateQuery($id: String!) {
    location: sanityLocations(id: { eq: $id }) {
      slug {
        current
      }
      name
      booknow {
        link
        text
        url
      }
      image {
        ...SanityImage
      }
      locationImage {
        ...SanityImage
      }
      address {
        street
        town
        county
        postcode
      }
      tel
      openingHours
      staff {
        title
        bio
        image {
          ...SanityImage
        }
        link
        socialTitle
        socialURL
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      name
      announcement {
        enabled
      }
      email
      keywords
      openGraph {
        title
        description
        image {
          ...SanityMainImage
        }
      }
      ...NavMenu
    }
  }
`;

const Page = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const location = data.location
  const site = data.site
  const hasAnnouncement = site.announcement.enabled
  const pageTitle = `${location.name} | ${site.name}`
  const img = imageUrlFor(buildImageObj(location?.image))
    .width(1600)
    .url()
  const locationImg = imageUrlFor(buildImageObj(location?.locationImage))
    .width(600)
    .height(600)
    .url()

    return (
    <Layout nav={site.navMenu} textWhite={true}>
      <SEO
        title={pageTitle}
        schema={schemaBuilder(location, site)}
        bodyAttr={{
          class: "leading-normal tracking-normal text-white bg-gray-800"
        }}
      />
      <div className={`${hasAnnouncement ? 'md:pt-40' : 'md:pt-24'}`}>
        <section style={{ backgroundImage: `url('${img}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', height: '65vh' }} className="relative text-white text-center">
          <div className="border-box items-center p-12 z-20 min-w-min border-2 bg-gray-900 bg-opacity-50 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
            <h1 className="text-3xl md:text-5xl uppercase text-white">{location.name}</h1>
          </div>
        </section>
        <Skew />

        <section className="py-12 px-6 md:p-24 text-center">
          <Heading text={'Choose a Master'} />
          <Subheading text={'All of our barbers are professionally trained and deemed masters by our highest quality standards.'} />
        </section>

        <LazyLoad height={400}>
          <StylistSection staff={location.staff} />
        </LazyLoad>

        <BookNow {...location.booknow[0]} />

        <section className="py-32">
          <LocationHours
            address={location.address}
            tel={location.tel}
            hours={location.openingHours}
            image={locationImg}
            email={site.email}
          />
        </section>

        <GrizzlyLook />
      </div>
    </Layout>
  );
};

function schemaBuilder(location, site) {
  const image = site?.openGraph?.image
  const metaImage =
    image && image.asset
      ? imageUrlFor(buildImageObj(image))
        .width(1200)
        .url()
      : "";

  return {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    "name": `${site?.name} - ${location?.name}`,
    "telephone": location?.tel || site?.tel,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": location?.address?.street,
      "addressLocality": location?.address?.town,
      "addressRegion": location?.address?.county,
      "postalCode": location?.address?.postcode
    },
    "description": location?.description || site?.description,
    "url": "https://grizzlysmalehairsalon.co.uk",
    "image": metaImage,
    "openingHours": ["Mo 09:00-17:00", "Tu, We, Th 09:00-20:00", "Fr 09:00-18:00", "Sa 08:30-17:00", "Sun 10:00-16:00"],
    "sameAs": ["https://www.facebook.com/Grizzlys-Male-Hair-Salon-345064302579412/", "https://twitter.com/GrizzlysMHS",
      "https://www.instagram.com/grizzlysmhs"
    ]
  }
}

export default Page;
